
import { useTable, usePagination } from 'react-table';
import React, { useState, useEffect } from 'react';
import { Card, CardContent } from '@mui/material';
import styled from '@emotion/styled';


const config = require('./config');

const StyledCard = styled(Card)`
  width: 90vw;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
`;

const ScrollableContent = styled.div`
  max-height: 50vh;
  overflow: auto;
  margin-bottom: auto; // Pushes the content up
`;


const Admin = () => {

  const [data, setData] = useState([]);
  const [treatments, setTreatments] = useState([]);

  const handleDownload = () => {
    fetch(`${config.baseURL}/exportResponses`)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'questions.csv';
        a.click();
      });
  };

  const fetchData = async (url, setData) => {
    try {
      const response = await fetch(url);
      const text = await response.text();
      const data = JSON.parse(text);
      setData(data);
    } catch (error) {
      console.error('Fetch error:', error);
    }
  };

  // Fetch data when component mounts
  useEffect(() => {
    fetchData(`${config.baseURL}/get-responses`, setData);
    fetchData(`${config.baseURL}/get-all-treatments`, setTreatments);
  }, []);

  // Define columns for the responses table
  //WARNING: first response must be completed entirely or it will show as JSON in the subsequent responses
  const columns = React.useMemo(
    //0 being the first response
    () => data[0] ? Object.keys(data[0]).flatMap(key => {
      let value = data[0][key];
      // Check if the value is a non-null object
      if (typeof value === 'object' && value !== null) {
        // If it is, create separate columns for each property of the object
        return Object.keys(value).map(subKey => {
          const column = {
            Header: `${key.toUpperCase()}_${subKey.toUpperCase()}`,
            accessor: row => row[key] && typeof row[key] === 'object' && row[key][subKey] !== null ? row[key][subKey] : null,
          };
          return column;
        });
      } else {
        // If it's not an object, create a column as before
        return {
          Header: key.toUpperCase(),
          accessor: key,
        };
      }
    }) : [],
    [data]
  );

  // Define columns for the treatments table
  const treatmentColumns = React.useMemo(
    () => treatments[0] && treatments[0].data ? Object.keys(treatments[0].data).map(key => ({
      Header: key.toUpperCase(),
      accessor: `data.${key}`,
    })) : [],
    [treatments]
  );
  function deleteFunction(demographicId, questionId, treatmentSetId, treatmentNumber) {
    // Display a confirmation dialog
    if (window.confirm('Are you sure you want to delete this row? This will affect the treatment, and the survey response.')) {
      console.log(`Delete row with demographic ID ${demographicId}`);

      // Fix the treatment counters first
      fetch(`${config.baseURL}/delete-treatment`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          treatmentSet: treatmentSetId,
          treatmentNumber: treatmentNumber
        })
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          fetchData(`${config.baseURL}/get-all-treatments`, setTreatments);
          // Next, delete the demographic
          return fetch(`${config.baseURL}/delete-demographic/${demographicId}`, {
            method: 'DELETE'
          });
        })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          fetchData(`${config.baseURL}/get-responses`, setData);
          // Then, delete the question if questionId is not null
          if (questionId !== null) {
            return fetch(`${config.baseURL}/delete-questions/${questionId}`, {
              method: 'DELETE'
            });
          }
        })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          // Fetch the updated data and treatments
          
        })
        .catch(error => console.error('Error:', error));
        
        
    }

  }

  // Use the useTable and usePagination hooks to create table functionality for survey responses
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    pageCount,
    gotoPage,
    state: { pageIndex },
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    usePagination
  );

  // Use the useTable and usePagination hooks to create treatments table functionality
  const {
    getTableProps: getTreatmentTableProps,
    getTableBodyProps: getTreatmentTableBodyProps,
    headerGroups: treatmentHeaderGroups,
    page: treatmentPage,
    nextPage: treatmentNextPage,
    previousPage: treatmentPreviousPage,
    canNextPage: treatmentCanNextPage,
    canPreviousPage: treatmentCanPreviousPage,
    pageOptions: treatmentPageOptions,
    pageCount: treatmentPageCount,
    gotoPage: treatmentGotoPage,
    state: { pageIndex: treatmentPageIndex },
    prepareRow: treatmentPrepareRow,
  } = useTable(
    {
      columns: treatmentColumns,
      data: treatments,
      initialState: { pageIndex: 0 },
    },
    usePagination
  );

  // Render the component
  return (
    <>

      {/* Container for the tables and controls */}
      <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', alignItems: 'center' }}>

        {/* Card for the treatments table */}
        <StyledCard>

          <CardContent>

            {/* Render the treatments table */}
            <table {...getTreatmentTableProps()} style={{ margin: '0 auto' }}>
              {/* Table header */}
              <thead>
                {treatmentHeaderGroups.map((headerGroup, headerGroupIndex) => {
                  return (
                    // Render each header group
                    <tr {...headerGroup.getHeaderGroupProps()} key={headerGroupIndex}>
                      <th>Treatment Set ID</th>
                      {headerGroup.headers.map((column, columnIndex) => {
                        // Render each column header
                        return (
                          <th {...column.getHeaderProps()} key={columnIndex}>{column.render('Header')}</th>
                        );
                      })}
                    </tr>
                  );
                })}
              </thead>

              {/* Table body */}
              <tbody {...getTreatmentTableBodyProps()}>
                {treatmentPage.map((row, rowIndex) => {
                  treatmentPrepareRow(row);
                  // Render each row
                  return (
                    <tr {...row.getRowProps()} key={rowIndex}>
                      <td style={{ textAlign: 'center' }}>{treatments[rowIndex].id}</td>
                      {row.cells.map((cell, cellIndex) => {
                        // Render each cell
                        return (
                          <td {...cell.getCellProps()} key={cellIndex} style={{ textAlign: 'center' }}>
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>

            {/* Render pagination controls for treatments */}
            <div>
              <button onClick={() => treatmentGotoPage(0)} disabled={!treatmentCanPreviousPage}>
                {'<<'}
              </button>{' '}
              <button onClick={() => treatmentPreviousPage()} disabled={!treatmentCanPreviousPage}>
                {'<'}
              </button>{' '}
              <button onClick={() => treatmentNextPage()} disabled={!treatmentCanNextPage}>
                {'>'}
              </button>{' '}
              <button onClick={() => treatmentGotoPage(treatmentPageCount - 1)} disabled={!treatmentCanNextPage}>
                {'>>'}
              </button>{' '}
              <span>
                Page{' '}
                <strong>
                  {treatmentPageIndex + 1} of {treatmentPageOptions.length}
                </strong>{' '}
              </span>
            </div>
          </CardContent>
        </StyledCard>

        <br></br>
        <div style={{ textAlign: 'right' }}>
          <button onClick={handleDownload}>
            Download CSV
          </button>
        </div>
        {/* Card for the responses table */}
        <StyledCard>

          <CardContent>
            <ScrollableContent>
              <table {...getTableProps()} style={{ margin: '0 auto', borderCollapse: 'collapse' }}>
                {/* Table header */}
                <thead>
                  {headerGroups.map((headerGroup, index) => {
                    const { key: headerGroupKey, ...headerGroupProps } = headerGroup.getHeaderGroupProps();
                    return (
                      // Render each header group
                      <tr {...headerGroupProps} key={index}>
                        <th style={{ border: '1px solid black', padding: '10px' }}>Action</th>
                        {headerGroup.headers.map((column, columnIndex) => {
                          const { key: columnKey, ...columnProps } = column.getHeaderProps();
                          // Render each column header
                          return (
                            <th {...columnProps} key={columnIndex} style={{ border: '1px solid black', padding: '10px' }}>{column.render('Header')}</th>
                          );
                        })}
                      </tr>
                    );
                  })}
                </thead>

                {/* Table body */}
                <tbody {...getTableBodyProps()}>
                  {page.map((row, rowIndex) => {
                    prepareRow(row);
                    // Render each row
                    console.log(row);
                    return (
                      <tr {...row.getRowProps()} key={rowIndex} style={{ backgroundColor: rowIndex % 2 ? '#eee' : 'white' }}>

                        <td style={{ border: '1px solid black', padding: '10px' }}>
                          {/* Delete button for each row */}

                          <button onClick={() => deleteFunction(row.original.id, row.original.question_id, row.original.treatmentSetId, row.original.treatmentNumber)}>Delete</button>
                        </td>
                        {row.cells.map((cell, cellIndex) => {
                          const { key: cellKey, ...cellProps } = cell.getCellProps();
                          // Render each cell
                          return (
                            <td {...cellProps} key={cellIndex} style={{ border: '1px solid black', padding: '10px' }}>
                              {/* Check if cell value is an object and stringify it if it is */}
                              {typeof cell.value === 'object' && cell.value !== null
                                ? JSON.stringify(cell.value)
                                : cell.render('Cell')}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </ScrollableContent>
            {/* Render pagination controls */}
            <div>
              <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                {'<<'}
              </button>{' '}
              <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                {'<'}
              </button>{' '}
              <button onClick={() => nextPage()} disabled={!canNextPage}>
                {'>'}
              </button>{' '}
              <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                {'>>'}
              </button>{' '}
              <span>
                Page{' '}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>{' '}
              </span>
            </div>
          </CardContent>
        </StyledCard>
      </div>
    </>
  );
};

// Export the Admin component
export default Admin;