import React, { useState, useEffect } from 'react';
import { Box, Card, Typography, CircularProgress, Rating, Button, FormControl, FormControlLabel, FormLabel, Grid, RadioGroup, Radio } from '@mui/material';
import styled from '@emotion/styled';
import { useLocation, useNavigate } from 'react-router-dom';
const config = require('./config');

const StyledCard = styled(Card)`
width: 80%;
margin: 2% auto;
padding: 20px;
max-height: 90vh;
overflow: auto;
`;
const StyledFormLabel = styled(FormLabel)`
  font-size: 1rem; /* adjust as needed */
  color: black; /* adjust as needed */
  font-weight: bold;
  &:focus {
    color: black;
  }
  &.Mui-focused {
    color: black;
  }
`;
//determines if each individual question is invalid
const isInvalid = (value) => value === '' || value === null || value === undefined;
    

const FirstQuestions = () => {
  const location = useLocation();
  const navigate = useNavigate();
  
  const { id: demographicId } = location.state;
  const [submitted, setSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [rating1, setRating1] = useState(null);
  const [rating2, setRating2] = useState(null);
  const [rating3, setRating3] = useState(null);
  const [rating4, setRating4] = useState(null);
  const [rating5, setRating5] = useState(null);
  const [rating6, setRating6] = useState(null);
  const [rating7, setRating7] = useState(null);
  const [rating8, setRating8] = useState(null);
  const [rating9, setRating9] = useState(null);
  const [rating10, setRating10] = useState(null);
  const [rating11, setRating11] = useState(null);
  const [rating12, setRating12] = useState(null);
  const [rating13, setRating13] = useState(null);
  const [rating14, setRating14] = useState(null);
  const [rating15, setRating15] = useState(null);
  const [rating16, setRating16] = useState(null);
  const [rating17, setRating17] = useState(null);
  const [rating18, setRating18] = useState(null);
  const [rating19, setRating19] = useState(null);
  const [rating20, setRating20] = useState(null);
  const [rating21, setRating21] = useState(null);
  const [rating22, setRating22] = useState(null);
  const [rating23, setRating23] = useState(null);

  useEffect(() => {
    if (demographicId) {
      setIsLoading(false);
    }
  }, [demographicId]);

  if (isLoading) {
    return <CircularProgress />;
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);

    const responses = {
      demographicId,
      rating1,
      rating2,
      rating3,
      rating4,
      rating5,
      rating6,
      rating7,
      rating8,
      rating9,
      rating10,
      rating11,
      rating12,
      rating13,
      rating14,
      rating15,
      rating16,
      rating17,
      rating18,
      rating19,
      rating20,
      rating21,
      rating22,
      rating23
    };
    console.log(responses);
    //set to true if there was an attempt at least
    setSubmitted(true);
    //determines if each of the responses are valid or not
    const allValid = Object.values(responses).every(rating => !isInvalid(rating));
    
    if (!allValid) {
      console.error('One or more ratings are invalid');
      
      alert('Please answer all the questions before submitting.');
      // each question will display red text if it is not submitted. 
  } else {
      fetch(`${config.baseURL}/create-questions`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(responses),
      })
      .then(response => response.json())
      .then(data => {
          // handle response data
          console.log('Success:', data);
          const questionsId = data.id;
          navigate('/questions2', { state: { questionsId } });
          // If the data includes an ID, you can save it to the state
      })
      .catch((error) => {
          console.error('Error:', error);
      });
  }
  };

  return (
    <StyledCard>
      <form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={2} direction="column">

          <Grid item>
            <FormControl component="fieldset">
              <StyledFormLabel component="legend">1. The previously mentioned use of personal information is an invasion of privacy.</StyledFormLabel>

              <RadioGroup
                aria-label="rating1"
                name="rating1"
                value={rating1}
                onChange={(event) => {
                  setRating1(event.target.value);
                }}
              >
                <FormControlLabel value="strongly disagree" control={<Radio />} label="Strongly Disagree" />
                <FormControlLabel value="somewhat disagree" control={<Radio />} label="Somewhat Disagree" />
                <FormControlLabel value="neither agree nor disagree" control={<Radio />} label="Neither Agree Nor Disagree" />
                <FormControlLabel value="somewhat agree" control={<Radio />} label="Somewhat Agree" />
                <FormControlLabel value="strongly agree" control={<Radio />} label="Strongly Agree" />
                <FormControlLabel value="no personal information given in the context" control={<Radio />} label="There was no personal information shared in the scenario." />
              </RadioGroup>
              {submitted && isInvalid(rating1) && <p style={{ color: 'red' }}>This question is required. </p>}
            </FormControl>
          </Grid>


          <Grid item>
            <FormControl component="fieldset">
              <StyledFormLabel component="legend">2. I would exert a great deal of effort to continue working for the carrier delivering to the store.</StyledFormLabel>

              <RadioGroup
                aria-label="rating2"
                name="rating2"
                value={rating2}
                onChange={(event) => {
                  setRating2(event.target.value);
                }}
              >
                <FormControlLabel value="strongly disagree" control={<Radio />} label="Strongly Disagree" />
                <FormControlLabel value="somewhat disagree" control={<Radio />} label="Somewhat Disagree" />
                <FormControlLabel value="neither agree nor disagree" control={<Radio />} label="Neither Agree Nor Disagree" />
                <FormControlLabel value="somewhat agree" control={<Radio />} label="Somewhat Agree" />
                <FormControlLabel value="strongly agree" control={<Radio />} label="Strongly Agree" />
              </RadioGroup>
              {submitted && isInvalid(rating2) && <p style={{ color: 'red' }}>This question is required. </p>}
            </FormControl>
          </Grid>

          <Grid item>
            <FormControl component="fieldset">
              <StyledFormLabel component="legend">3.  I am interested in pursuing my long-term career with the carrier delivering to the store.</StyledFormLabel>

              <RadioGroup
                aria-label="rating3"
                name="rating3"
                value={rating3}
                onChange={(event) => {
                  setRating3(event.target.value);
                }}
              >
                <FormControlLabel value="strongly disagree" control={<Radio />} label="Strongly Disagree" />
                <FormControlLabel value="somewhat disagree" control={<Radio />} label="Somewhat Disagree" />
                <FormControlLabel value="neither agree nor disagree" control={<Radio />} label="Neither Agree Nor Disagree" />
                <FormControlLabel value="somewhat agree" control={<Radio />} label="Somewhat Agree" />
                <FormControlLabel value="strongly agree" control={<Radio />} label="Strongly Agree" />
              </RadioGroup>
              {submitted && isInvalid(rating3) && <p style={{ color: 'red' }}>This question is required. </p>}
            </FormControl>
          </Grid>


          <Grid item>
            <FormControl component="fieldset">
              <StyledFormLabel component="legend">4. I would like to continue working for the carrier delivering to the store.</StyledFormLabel>

              <RadioGroup
                aria-label="rating4"
                name="rating4"
                value={rating4}
                onChange={(event) => {
                  setRating4(event.target.value);
                }}
              >
                <FormControlLabel value="strongly disagree" control={<Radio />} label="Strongly Disagree" />
                <FormControlLabel value="somewhat disagree" control={<Radio />} label="Somewhat Disagree" />
                <FormControlLabel value="neither agree nor disagree" control={<Radio />} label="Neither Agree Nor Disagree" />
                <FormControlLabel value="somewhat agree" control={<Radio />} label="Somewhat Agree" />
                <FormControlLabel value="strongly agree" control={<Radio />} label="Strongly Agree" />
              </RadioGroup>
              {submitted && isInvalid(rating4) && <p style={{ color: 'red' }}>This question is required. </p>}
            </FormControl>
          </Grid>




          <Grid item>
            <FormControl component="fieldset">
              <StyledFormLabel component="legend">5. I am no longer interested in the carrier delivering to the store except as a last resort.</StyledFormLabel>

              <RadioGroup
                aria-label="rating5"
                name="rating5"
                value={rating5}
                onChange={(event) => {
                  setRating5(event.target.value);
                }}
              >
                <FormControlLabel value="strongly disagree" control={<Radio />} label="Strongly Disagree" />
                <FormControlLabel value="somewhat disagree" control={<Radio />} label="Somewhat Disagree" />
                <FormControlLabel value="neither agree nor disagree" control={<Radio />} label="Neither Agree Nor Disagree" />
                <FormControlLabel value="somewhat agree" control={<Radio />} label="Somewhat Agree" />
                <FormControlLabel value="strongly agree" control={<Radio />} label="Strongly Agree" />
              </RadioGroup>
              {submitted && isInvalid(rating5) && <p style={{ color: 'red' }}>This question is required. </p>}
            </FormControl>
          </Grid>

          <Grid item>
            <FormControl component="fieldset">
              <StyledFormLabel component="legend">6. What is your overall attitude toward the carrier delivering to the store?</StyledFormLabel>

              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <Typography>Very Unfavorable</Typography>
                </Grid>
                <Grid item>
                  <Rating
                    name="rating-6"
                    value={rating6}
                    max={7}
                    onChange={(event, newValue) => {
                      setRating6(newValue);
                    }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Very Favorable</Typography>
                </Grid>
              </Grid>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl component="fieldset">


              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <Typography>Extremely Bad</Typography>
                </Grid>
                <Grid item>
                  <Rating
                    name="rating-7"
                    value={rating7}
                    max={7}
                    onChange={(event, newValue) => {
                      setRating7(newValue);
                    }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Extremely Good</Typography>
                </Grid>
              </Grid>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl component="fieldset">


              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <Typography>Extremely Negative</Typography>
                </Grid>
                <Grid item>
                  <Rating
                    name="rating-8"
                    value={rating8}
                    max={7}
                    onChange={(event, newValue) => {
                      setRating8(newValue);
                    }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Extremely Positive</Typography>
                </Grid>
              </Grid>
            </FormControl>
            {submitted && (isInvalid(rating6) || isInvalid(rating7) || isInvalid(rating8)) && <p style={{ color: 'red' }}>This question is required. </p>}
          </Grid>

          <Grid item>
            <FormControl component="fieldset">
              <StyledFormLabel component="legend">7. I would be very happy to spend the rest of my career with the carrier delivering to the store.</StyledFormLabel>

              <RadioGroup
                aria-label="rating9"
                name="rating9"
                value={rating9}
                onChange={(event) => {
                  setRating9(event.target.value);
                }}
              >
                <FormControlLabel value="strongly disagree" control={<Radio />} label="Strongly Disagree" />
                <FormControlLabel value="somewhat disagree" control={<Radio />} label="Somewhat Disagree" />
                <FormControlLabel value="neither agree nor disagree" control={<Radio />} label="Neither Agree Nor Disagree" />
                <FormControlLabel value="somewhat agree" control={<Radio />} label="Somewhat Agree" />
                <FormControlLabel value="strongly agree" control={<Radio />} label="Strongly Agree" />
              </RadioGroup>
              {submitted && isInvalid(rating9) && <p style={{ color: 'red' }}>This question is required. </p>}
            </FormControl>
          </Grid>


          <Grid item>
            <FormControl component="fieldset">
              <StyledFormLabel component="legend">8. I enjoy discussing my company with people outside it.</StyledFormLabel>

              <RadioGroup
                aria-label="rating10"
                name="rating10"
                value={rating10}
                onChange={(event) => {
                  setRating10(event.target.value);
                }}
              >
                <FormControlLabel value="strongly disagree" control={<Radio />} label="Strongly Disagree" />
                <FormControlLabel value="somewhat disagree" control={<Radio />} label="Somewhat Disagree" />
                <FormControlLabel value="neither agree nor disagree" control={<Radio />} label="Neither Agree Nor Disagree" />
                <FormControlLabel value="somewhat agree" control={<Radio />} label="Somewhat Agree" />
                <FormControlLabel value="strongly agree" control={<Radio />} label="Strongly Agree" />
              </RadioGroup>
              {submitted && isInvalid(rating10) && <p style={{ color: 'red' }}>This question is required. </p>}
            </FormControl>
          </Grid>


        


          <Grid item>
            <FormControl component="fieldset">
              <StyledFormLabel component="legend">9. I do not feel like "part of the family" at the carrier delivering to the store.</StyledFormLabel>

              <RadioGroup
                aria-label="rating11"
                name="rating11"
                value={rating11}
                onChange={(event) => {
                  setRating11(event.target.value);
                }}
              >
                <FormControlLabel value="strongly disagree" control={<Radio />} label="Strongly Disagree" />
                <FormControlLabel value="somewhat disagree" control={<Radio />} label="Somewhat Disagree" />
                <FormControlLabel value="neither agree nor disagree" control={<Radio />} label="Neither Agree Nor Disagree" />
                <FormControlLabel value="somewhat agree" control={<Radio />} label="Somewhat Agree" />
                <FormControlLabel value="strongly agree" control={<Radio />} label="Strongly Agree" />
              </RadioGroup>
              {submitted && isInvalid(rating11) && <p style={{ color: 'red' }}>This question is required. </p>}
            </FormControl>
          </Grid>

          <Grid item>
            <FormControl component="fieldset">
              <StyledFormLabel component="legend">10. I do not feel emotionally attached to the carrier delivering to the store.</StyledFormLabel>

              <RadioGroup
                aria-label="rating12"
                name="rating12"
                value={rating12}
                onChange={(event) => {
                  setRating12(event.target.value);
                }}
              >
                <FormControlLabel value="strongly disagree" control={<Radio />} label="Strongly Disagree" />
                <FormControlLabel value="somewhat disagree" control={<Radio />} label="Somewhat Disagree" />
                <FormControlLabel value="neither agree nor disagree" control={<Radio />} label="Neither Agree Nor Disagree" />
                <FormControlLabel value="somewhat agree" control={<Radio />} label="Somewhat Agree" />
                <FormControlLabel value="strongly agree" control={<Radio />} label="Strongly Agree" />
              </RadioGroup>
              {submitted && isInvalid(rating12) && <p style={{ color: 'red' }}>This question is required. </p>}
            </FormControl>
          </Grid>

          <Grid item>
            <FormControl component="fieldset">
              <StyledFormLabel component="legend">11. The carrier delivering to the store has a great deal of personal meaning for me.</StyledFormLabel>

              <RadioGroup
                aria-label="rating13"
                name="rating13"
                value={rating13}
                onChange={(event) => {
                  setRating13(event.target.value);
                }}
              >
                <FormControlLabel value="strongly disagree" control={<Radio />} label="Strongly Disagree" />
                <FormControlLabel value="somewhat disagree" control={<Radio />} label="Somewhat Disagree" />
                <FormControlLabel value="neither agree nor disagree" control={<Radio />} label="Neither Agree Nor Disagree" />
                <FormControlLabel value="somewhat agree" control={<Radio />} label="Somewhat Agree" />
                <FormControlLabel value="strongly agree" control={<Radio />} label="Strongly Agree" />
              </RadioGroup>
              {submitted && isInvalid(rating13) && <p style={{ color: 'red' }}>This question is required. </p>}
            </FormControl>
          </Grid>

          <Grid item>
            <FormControl component="fieldset">
              <StyledFormLabel component="legend">12. I do not feel a strong sense of belonging to the carrier delivering to the store.</StyledFormLabel>

              <RadioGroup
                aria-label="rating14"
                name="rating14"
                value={rating14}
                onChange={(event) => {
                  setRating14(event.target.value);
                }}
              >
                <FormControlLabel value="strongly disagree" control={<Radio />} label="Strongly Disagree" />
                <FormControlLabel value="somewhat disagree" control={<Radio />} label="Somewhat Disagree" />
                <FormControlLabel value="neither agree nor disagree" control={<Radio />} label="Neither Agree Nor Disagree" />
                <FormControlLabel value="somewhat agree" control={<Radio />} label="Somewhat Agree" />
                <FormControlLabel value="strongly agree" control={<Radio />} label="Strongly Agree" />
              </RadioGroup>
              {submitted && isInvalid(rating14) && <p style={{ color: 'red' }}>This question is required. </p>}
            </FormControl>
          </Grid>

          <Grid item>
            <FormControl component="fieldset">
              <StyledFormLabel component="legend">13. I think that people these days move from company to company too often.</StyledFormLabel>

              <RadioGroup
                aria-label="rating15"
                name="rating15"
                value={rating15}
                onChange={(event) => {
                  setRating15(event.target.value);
                }}
              >
                <FormControlLabel value="strongly disagree" control={<Radio />} label="Strongly Disagree" />
                <FormControlLabel value="somewhat disagree" control={<Radio />} label="Somewhat Disagree" />
                <FormControlLabel value="neither agree nor disagree" control={<Radio />} label="Neither Agree Nor Disagree" />
                <FormControlLabel value="somewhat agree" control={<Radio />} label="Somewhat Agree" />
                <FormControlLabel value="strongly agree" control={<Radio />} label="Strongly Agree" />
              </RadioGroup>
              {submitted && isInvalid(rating15) && <p style={{ color: 'red' }}>This question is required. </p>}
            </FormControl>
          </Grid>

          <Grid item>
            <FormControl component="fieldset">
              <StyledFormLabel component="legend">14. I do not believe that a person must always be loyal to his or her company.</StyledFormLabel>

              <RadioGroup
                aria-label="rating16"
                name="rating16"
                value={rating16}
                onChange={(event) => {
                  setRating16(event.target.value);
                }}
              >
                <FormControlLabel value="strongly disagree" control={<Radio />} label="Strongly Disagree" />
                <FormControlLabel value="somewhat disagree" control={<Radio />} label="Somewhat Disagree" />
                <FormControlLabel value="neither agree nor disagree" control={<Radio />} label="Neither Agree Nor Disagree" />
                <FormControlLabel value="somewhat agree" control={<Radio />} label="Somewhat Agree" />
                <FormControlLabel value="strongly agree" control={<Radio />} label="Strongly Agree" />
              </RadioGroup>
              {submitted && isInvalid(rating16) && <p style={{ color: 'red' }}>This question is required. </p>}
            </FormControl>
          </Grid>

          <Grid item>
            <FormControl component="fieldset">
              <StyledFormLabel component="legend">15. Jumping from company to company does not seem at all unethical to me.</StyledFormLabel>

              <RadioGroup
                aria-label="rating17"
                name="rating17"
                value={rating17}
                onChange={(event) => {
                  setRating17(event.target.value);
                }}
              >
                <FormControlLabel value="strongly disagree" control={<Radio />} label="Strongly Disagree" />
                <FormControlLabel value="somewhat disagree" control={<Radio />} label="Somewhat Disagree" />
                <FormControlLabel value="neither agree nor disagree" control={<Radio />} label="Neither Agree Nor Disagree" />
                <FormControlLabel value="somewhat agree" control={<Radio />} label="Somewhat Agree" />
                <FormControlLabel value="strongly agree" control={<Radio />} label="Strongly Agree" />
              </RadioGroup>
              {submitted && isInvalid(rating17) && <p style={{ color: 'red' }}>This question is required. </p>}
            </FormControl>
          </Grid>

          <Grid item>
            <FormControl component="fieldset">
              <StyledFormLabel component="legend">16. One of the major reasons I continue to work for the carrier delivering to the store is that I believe that loyalty is important and therefore feel a sense of moral obligation to remain.</StyledFormLabel>

              <RadioGroup
                aria-label="rating18"
                name="rating18"
                value={rating18}
                onChange={(event) => {
                  setRating18(event.target.value);
                }}
              >
                <FormControlLabel value="strongly disagree" control={<Radio />} label="Strongly Disagree" />
                <FormControlLabel value="somewhat disagree" control={<Radio />} label="Somewhat Disagree" />
                <FormControlLabel value="neither agree nor disagree" control={<Radio />} label="Neither Agree Nor Disagree" />
                <FormControlLabel value="somewhat agree" control={<Radio />} label="Somewhat Agree" />
                <FormControlLabel value="strongly agree" control={<Radio />} label="Strongly Agree" />
              </RadioGroup>
              {submitted && isInvalid(rating18) && <p style={{ color: 'red' }}>This question is required. </p>}
            </FormControl>
          </Grid>

          <Grid item>
            <FormControl component="fieldset">
              <StyledFormLabel component="legend">17. If I get another offer for a better job elsewhere, I will not feel it was right to leave the carrier delivering to the store.</StyledFormLabel>

              <RadioGroup
                aria-label="rating19"
                name="rating19"
                value={rating19}
                onChange={(event) => {
                  setRating19(event.target.value);
                }}
              >
                <FormControlLabel value="strongly disagree" control={<Radio />} label="Strongly Disagree" />
                <FormControlLabel value="somewhat disagree" control={<Radio />} label="Somewhat Disagree" />
                <FormControlLabel value="neither agree nor disagree" control={<Radio />} label="Neither Agree Nor Disagree" />
                <FormControlLabel value="somewhat agree" control={<Radio />} label="Somewhat Agree" />
                <FormControlLabel value="strongly agree" control={<Radio />} label="Strongly Agree" />
              </RadioGroup>
              {submitted && isInvalid(rating19) && <p style={{ color: 'red' }}>This question is required. </p>}
            </FormControl>
          </Grid>

          <Grid item>
            <FormControl component="fieldset">
              <StyledFormLabel component="legend">18. I am taught to believe in the value of remaining loyal to one company.</StyledFormLabel>

              <RadioGroup
                aria-label="rating20"
                name="rating20"
                value={rating20}
                onChange={(event) => {
                  setRating20(event.target.value);
                }}
              >
                <FormControlLabel value="strongly disagree" control={<Radio />} label="Strongly Disagree" />
                <FormControlLabel value="somewhat disagree" control={<Radio />} label="Somewhat Disagree" />
                <FormControlLabel value="neither agree nor disagree" control={<Radio />} label="Neither Agree Nor Disagree" />
                <FormControlLabel value="somewhat agree" control={<Radio />} label="Somewhat Agree" />
                <FormControlLabel value="strongly agree" control={<Radio />} label="Strongly Agree" />
              </RadioGroup>
              {submitted && isInvalid(rating20) && <p style={{ color: 'red' }}>This question is required. </p>}
            </FormControl>
          </Grid>

          <Grid item>
            <FormControl component="fieldset">
              <StyledFormLabel component="legend">19. Things are better in the days when people stay with one company for most of their careers.</StyledFormLabel>

              <RadioGroup
                aria-label="rating21"
                name="rating21"
                value={rating21}
                onChange={(event) => {
                  setRating21(event.target.value);
                }}
              >
                <FormControlLabel value="strongly disagree" control={<Radio />} label="Strongly Disagree" />
                <FormControlLabel value="somewhat disagree" control={<Radio />} label="Somewhat Disagree" />
                <FormControlLabel value="neither agree nor disagree" control={<Radio />} label="Neither Agree Nor Disagree" />
                <FormControlLabel value="somewhat agree" control={<Radio />} label="Somewhat Agree" />
                <FormControlLabel value="strongly agree" control={<Radio />} label="Strongly Agree" />
              </RadioGroup>
              {submitted && isInvalid(rating21) && <p style={{ color: 'red' }}>This question is required. </p>}
            </FormControl>
          </Grid>

          <Grid item>
            <FormControl component="fieldset">
              <StyledFormLabel component="legend">20. I do not think that wanting to be a "company man" or "company woman" is sensible anymore.</StyledFormLabel>

              <RadioGroup
                aria-label="rating22"
                name="rating22"
                value={rating22}
                onChange={(event) => {
                  setRating22(event.target.value);
                }}
              >
                <FormControlLabel value="strongly disagree" control={<Radio />} label="Strongly Disagree" />
                <FormControlLabel value="somewhat disagree" control={<Radio />} label="Somewhat Disagree" />
                <FormControlLabel value="neither agree nor disagree" control={<Radio />} label="Neither Agree Nor Disagree" />
                <FormControlLabel value="somewhat agree" control={<Radio />} label="Somewhat Agree" />
                <FormControlLabel value="strongly agree" control={<Radio />} label="Strongly Agree" />
              </RadioGroup>
              {submitted && isInvalid(rating22) && <p style={{ color: 'red' }}>This question is required. </p>}
            </FormControl>
          </Grid>

          <Grid item>
            <FormControl component="fieldset">
              <StyledFormLabel component="legend">21. How likely is it that you will look for a job outside the carrier delivering to the store?</StyledFormLabel>

              <RadioGroup
                aria-label="rating23"
                name="rating23"
                value={rating23}
                onChange={(event) => {
                  setRating23(event.target.value);
                }}
              >
                <FormControlLabel value="very unlikely" control={<Radio />} label="Very Unlikely" />
                <FormControlLabel value="unlikely" control={<Radio />} label="Unlikely" />
                <FormControlLabel value="neither likely or unlikely" control={<Radio />} label="Neither Likely or Unlikely" />
                <FormControlLabel value="likely" control={<Radio />} label="Likely" />
                <FormControlLabel value="very likely" control={<Radio />} label="Very Likely" />
              </RadioGroup>
              {submitted && isInvalid(rating23) && <p style={{ color: 'red' }}>This question is required. </p>}
            </FormControl>
          </Grid>

          {/* Have to use a box in order to get next button right aligned. */}
          <Box display="flex" justifyContent="flex-end">
            <Button variant="contained" color="primary" type="submit">
              Next
            </Button>
          </Box>
        </Grid>
      </form>
    </StyledCard>
  );
};

export default FirstQuestions;