import React, { useState, useEffect } from 'react';
import { Box, Card, CardContent, Typography, Button, Grid, CircularProgress } from '@mui/material';

import styled from '@emotion/styled';
import { useLocation, useNavigate } from 'react-router-dom';

const StyledCard = styled(Card)`
width: 80%;
margin: 2% auto;
padding: 20px;
max-height: 90vh;
overflow: auto;
`;

const Phone = styled.div`
  width: 300px;
  height: 500px;
  border-radius: 40px;
  background: #1B1B1B;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 20px 40px 20px; // Increase top padding to make the bezel bigger at the top
`;

const Screen = styled.div`
  width: 100%;
  height: 100%;
  background: white;
  border-radius: 30px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 15px; // Reset padding to previous value
`;
const GrayBox = styled.div`
  width: 100%;
  background: #D3D3D3; // Gray color
  border-radius: 10px; // Rounded edges
  padding: 10px; // Space inside the box
  margin-bottom: 10px; // Space between boxes
`;
const CircleImage = styled.img`
  border-radius: 50%; // Make the image circular
  width: 100px; // Increase the size of the image
  height: 100px; // Increase the size of the image
  margin-right: 10px; // Space between the image and the text
`;

const Scenarios = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { person, id, firstName, imageSrc } = location.state;
  const [isLoading, setIsLoading] = useState(true);
  const treatment = location.state.treatmentNumber;
  useEffect(() => {
    if (person) {
      setIsLoading(false);
    }
  }, [person]);
  const handleNext = () => {
    navigate('/questionintroduction', { state: { id } });
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <StyledCard>
      <CardContent>
        <Grid container spacing={2}>


          <Grid item xs={12}>
            <Typography variant="h6" align="center" style={{ fontStyle: 'italic' }}>
              The retailer has enabled end consumers to scan a barcode on the shelf to get more information about the supply chain of the product (in this picture coffee).
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <div style={{ marginRight: '20px', height: '500px', border: 'none' }}>
                <img src="shelf.png" alt="" style={{ maxHeight: '100%', objectFit: 'contain', boxShadow: '0px 10px 20px rgba(0,0,0,0.15)' }} />
              </div>

              <Phone>
                <Screen>
                  <GrayBox style={{ display: 'flex', alignItems: 'center' }}>
                    <CircleImage src="coffeeBag.png" alt="Coffee Bag" />
                    <Typography variant="h6" style={{ marginLeft: '10px' }}>
                      Coffee Beans
                    </Typography>
                  </GrayBox>
                  {treatment === 4 && (
                    <GrayBox style={{ display: 'flex', alignItems: 'center' }}>

                      <CircleImage src={imageSrc} alt="" />
                      <Typography variant="body1">
                        <strong>Thank you!</strong> <br />
                        The trucking company and consumers are highly appreciative of {firstName}'s work of delivering the products to the store.
                      </Typography>
                    </GrayBox>
                  )}
                  {(treatment === 3) && (
                    <GrayBox>
                      <Typography variant="body1">
                        <strong>Thank you!</strong> <br />
                        The trucking company and consumers are highly appreciative of {firstName}'s work of delivering the products to the store.
                      </Typography>
                    </GrayBox>
                  )}
                  {treatment === 2 && (
                    <GrayBox>
                      <Typography variant="body1">
                        <strong>Thank you!</strong> <br />
                        The trucking company and consumers are highly appreciative of the driver's work of delivering the products to the store.
                      </Typography>
                    </GrayBox>
                  )}

                  <GrayBox>
                    <Typography variant="h6">
                      Description: <br></br>
                    </Typography>
                    <Typography variant="body2">
                      A classic medium roast with a smooth, rich, balanced flavor. <br></br>
                    </Typography>
                    <br></br>
                    <Typography variant="body2">
                      We have been perfecting the grind and blend for generations in order to provide you with the perfect morning cup of coffee.
                    </Typography>
                  </GrayBox>
                </Screen>
              </Phone>
            </div>
          </Grid>
        </Grid>
        {/* Have to use a box in order to get next button right aligned. */}
        <Box display="flex" justifyContent="flex-end">
          <Button id="next" variant="contained" color="primary" type="submit" onClick={handleNext}>
            Next
          </Button>
        </Box>
      </CardContent>
    </StyledCard>
  );
};

export default Scenarios;