import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Button, Grid, CircularProgress } from '@mui/material';
import styled from '@emotion/styled';
import { useLocation, useNavigate } from 'react-router-dom';

const StyledCard = styled(Card)`
width: 80%;
margin: 2% auto;
padding: 20px;
max-height: 90vh;
overflow: auto;
`;



const ThankYou = () => {
  const navigate = useNavigate();
  

  const handleNext = () => {
    navigate('/');
  };

  useEffect(() => {
    const timer = setTimeout(handleNext, 10000);
    return () => clearTimeout(timer); // This will clear the timeout if the component unmounts before the timeout finishes
  }, []);
  
  return (
    <StyledCard>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
           
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" align="center">
              Thank you for your submission. You have successfully completed the survey.
            </Typography>
          </Grid>
        
          <Grid item xs={12}  align="center"> 
            <Button variant="contained" color="primary" onClick={handleNext}>
              End
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </StyledCard>
  );
};

export default ThankYou;