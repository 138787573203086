// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import styled from '@emotion/styled';
import ConsentScreen from './ConsentScreen';
import Demographics from './Demographics';
import Scenarios from './Scenarios';
import Questions from './Questions';
import Questions2 from './Questions2';
import ThankYou from './ThankYou';
import Introduction from './Introduction';
import QuestionIntroduction from './QuestionIntroduction';
import ScenarioIntroduction from './ScenarioIntroduction';
import Admin from './Admin';
import Initial from './Initial';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
`;

function App() {


  return (
    <Container>
      <Router>
        <Routes>
          <Route path="/demographics" element={<Demographics />} />
          <Route path="/scenarios" element={<Scenarios />} />
          <Route path="/questions" element={<Questions />} />
          <Route path="/questions2" element={<Questions2 />} />
          <Route path="/thank-you" element={<ThankYou />} />
          <Route path="/introduction" element={<Introduction />} />
          <Route path="/questionintroduction" element={<QuestionIntroduction />} />
          <Route path="/scenariointroduction" element={<ScenarioIntroduction />} />
          <Route path="/consent" element={<ConsentScreen />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/" element={<Initial />} />
          
        </Routes>
      </Router>
    </Container>
  );
}

export default App;