// ConsentScreen.js
import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useNavigate, useLocation } from 'react-router-dom';

const config = require('./config');

const StyledCard = styled(Card)`
width: 80%;
margin: 2% auto;
padding: 20px;
max-height: 90vh;
overflow: auto;
`;

const ConsentScreen = ({ setConsent }) => {

  const location = useLocation();
  const treatmentData = location.state?.treatment;
  const treatmentNumber = treatmentData.treatmentNumber;
  const treatmentSet = treatmentData.setId;
  const navigate = useNavigate();
useEffect(() => {


}, []);


const handleConsent = async () => {
  try {
    const response = await fetch(`${config.baseURL}/create-demographics`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ treatmentSetId: treatmentSet, treatmentNumber: treatmentNumber })
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    const respondentId = data.id;
    navigate('/introduction', { state: { id: respondentId, treatmentNumber: treatmentNumber } });
  } catch (err) {
    console.error(err);
  }
};

const disclaimerOptions = [
  'This survey is not connected to the Internet and your answers will anonymously be recorded locally on the machine. The researcher who is administrating this survey to you is ready to answer any questions you might have. You are free to refuse to participate or to withdraw from this study at any time without any penalty.',
  'This survey is not connected to the Internet and your answers will anonymously be recorded locally on the machine. The researcher who is administrating this survey to you is ready to answer any questions you might have. You are free to refuse to participate or to withdraw from this study at any time without any penalty.',
  'This survey is not connected to the Internet and your answers will anonymously be recorded locally on the machine. Your name will not be stored with your answers. The researcher who is administrating this survey to you is ready to answer any questions you might have. You are free to refuse to participate or to withdraw from this study at any time without any penalty.',
  'This survey is not connected to the Internet and your answers will anonymously be recorded locally on the machine. Your name and your photo will not be stored with your answers, but are automatically deleted when you click the finish button, when a new user takes the survey or when the computer or tablet restarts (whichever comes first). The researcher who is administrating this survey to you is ready to answer any questions you might have. You are free to refuse to participate or to withdraw from this study at any time without any penalty.',
];

const disclaimer = disclaimerOptions[treatmentNumber - 1];

  return (
    <StyledCard>
      <CardContent>
        <Box display="flex" flexDirection="column" alignItems="center">

          <Typography variant="body1" gutterBottom>
            {disclaimer}
          </Typography>
          <Button 
            variant="contained" 
            onClick={handleConsent}
            sx={{
              backgroundColor: 'green', 
              color: 'white', 
              '&:hover': {
                backgroundColor: 'darkgreen', 
              },
            }}
          >
            Give Consent
          </Button>
        </Box>
      </CardContent>
    </StyledCard>
  );
};

export default ConsentScreen;