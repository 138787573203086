import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Button, Grid, CircularProgress } from '@mui/material';
import styled from '@emotion/styled';
import { useLocation, useNavigate } from 'react-router-dom';

const StyledCard = styled(Card)`
width: 80%;
margin: 2% auto;
padding: 20px;
max-height: 90vh;
overflow: auto;
`;



const Introduction = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleNext = () => {
    navigate('/demographics', { state: location.state });
  };

  useEffect(() => {
    
  }, []);
  
  return (
    <StyledCard>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
           
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" align="center">
            Thank you for participating our study!
In the following pages, you are going to read a scenario and answer a series of questions.
            </Typography>
          </Grid>
        
          <Grid item xs={12}  align="center"> 
            <Button variant="contained" color="primary" onClick={handleNext}>
              Continue
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </StyledCard>
  );
};

export default Introduction;