import React from 'react';
import { Card, CardContent, Typography, Button, Grid} from '@mui/material';
import styled from '@emotion/styled';
import { useLocation, useNavigate } from 'react-router-dom';

const StyledCard = styled(Card)`
width: 80%;
margin: 2% auto;
padding: 20px;
max-height: 90vh;
overflow: auto;
`;


const ScenarioIntroduction = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;
  console.log(state);
  const handleNext = () => {
    navigate('/scenarios', { state }); //pass along the state object from Demographics
  };


  return (
    <StyledCard>
      <CardContent>
        <Grid container spacing={2}>

          <Grid item xs={12}>
            <Typography variant="h5" align="center">
              Suppose you are an employed truck driver, working for a trucking company which has a fleet of more than 1000 proprietary trucks. The trucking company hauls loads to customers in North America and its mission is to operate the most efficient transport network. Your job as an employee of the company is to haul the products from production sites to your customers’ warehouses in the company’s truck. This time, you are delivering boxes of coffee to the retailer's cross dock, which will later go to the retailer’s store.
            </Typography>
          </Grid>

          <Grid item xs={12} align="center">
            <Button id="continue" variant="contained" color="primary" onClick={handleNext}>
              Continue
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </StyledCard>
  );
};

export default ScenarioIntroduction;