// Demographics.js
import React, { useState, useRef } from 'react';
import { Card, CardContent, TextField, RadioGroup, FormLabel, FormControlLabel, Radio, FormControl, Button, Grid, Box} from '@mui/material';
import styled from '@emotion/styled';
import { useNavigate, useLocation } from 'react-router-dom';
import Webcam from "react-webcam";
import CameraAltIcon from '@mui/icons-material/CameraAlt';
const config = require('./config');

const StyledCard = styled(Card)`
  width: 80%;
  max-height: 100vh; // Set the maximum height to 100% of the viewport height
  overflow-y: auto; // Enable vertical scrolling
  margin: 2%;
`;
const StyledFormLabel = styled(FormLabel)`
  font-size: 1rem; 
  color: black; 
  font-weight: bold;
  &:focus {
    color: black;
  }
  &.Mui-focused {
    color: black;
  }
`;

const Demographics = () => {
    const [firstName, setFirstName] = useState('');
    const [ownerOperator, setOwnerOperator] = useState('');
    const [experience, setExperience] = useState('');
    const [goHome, setGoHome] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [imageSrc, setImageSrc] = useState(null);
    const [showCamera, setShowCamera] = useState(false);
    const webcamRef = useRef(null);
    const [photoTaken, setPhotoTaken] = useState(false);

    const navigate = useNavigate();
    const location = useLocation(); 
    //get ID and treatment from state
    const state = location.state
    const treatment = location.state.treatmentNumber;

    const goHomeOptions = ['0-1 times', '2-3 times', '4-5 times', '5 or more times'];
    const experienceOptions = ['0-1 year', '1-5 years', '6-10 years', 'More than 10 years'];
    const ownerOperatorOptions = ['Yes', 'No'];

    const isInvalid = (value) => value === '' || value === null || value === undefined;

    const capture = () => {
        if (webcamRef.current) { //getting webcamRef errors without this
            const imageSrc = webcamRef.current.getScreenshot();
            setImageSrc(imageSrc);
            setPhotoTaken(true);
        }
    };

    const handleTakePhoto = () => {
        if (!showCamera) {
            setShowCamera(true);
        } else if (!photoTaken) {
            capture();
        } else {
            handleRetakePhoto();
        }
    };

    const handleRetakePhoto = () => {
        setPhotoTaken(false);
        setShowCamera(true);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setSubmitted(true);


        if (treatment === 4 && !photoTaken) {
            alert('Please take a photo before submitting the form.');
            return;
        }

        if (treatment === 3 || treatment === 4) {
            if (isInvalid(firstName)) {
                alert('Please enter your first name');
                return;
            }
        }
        if (treatment === 3 || treatment === 4) {
            state.firstName = firstName;
        }

        if (treatment === 4 && photoTaken) {
            state.imageSrc = imageSrc;
        }



        if (!isInvalid(ownerOperator) && !isInvalid(experience) && !isInvalid(goHome)) {
            const postPerson = {
                yearInIndustry: experience,
                returnHome: goHome,
                ownerOperator,
            };

            fetch(`${config.baseURL}/update-demographics/${state.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(postPerson),
            })
                .then(response => response.json())
                .then(data => {
                    if (data.message) {
                        alert(data.message);
                    } else {
                        state.person = data;
                        navigate('/scenariointroduction', { state: state });
                    }
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        }
    };

    return (
        <StyledCard>
            <CardContent>
                <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                    <Grid container spacing={2} direction="column">
                        {(treatment === 3 || treatment === 4) && (
                            <Grid item>
                                <StyledFormLabel id="firstName" variant="h6">Please enter your first name:</StyledFormLabel>
                                <br></br>
                                <TextField
                                    id="firstName"
                                    label="First Name"
                                    variant="outlined"
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                    error={submitted && isInvalid(firstName)}
                                    helperText={submitted && isInvalid(firstName) ? 'First name is required' : ''}
                                />
                            </Grid>
                        )}
                        {treatment === 4 && (
                            <Grid item>
                                <StyledFormLabel>
                                    Please take a photo of yourself:
                                </StyledFormLabel>
                                <Box display="flex" justifyContent="left" alignItems="left" flexDirection="column">
                                    {photoTaken && (
                                        <Box mt={2}>
                                            <img src={imageSrc} alt="User" />
                                        </Box>
                                    )}
                                    {!photoTaken && showCamera && (
                                        <Box mt={2}>
                                            <Webcam audio={false} ref={webcamRef} screenshotFormat="image/jpeg" />
                                        </Box>
                                    )}
                                    {photoTaken &&  <p style={{ color: 'green' }}>Photo was captured successfully. You can choose to retake it if you want, otherwise answer the following questions. </p>}
                                    
                                  
                                    
                                    <Button variant="contained" id="camera" color="primary" onClick={handleTakePhoto}>
                                        <CameraAltIcon />
                                        {!showCamera ? 'Activate Camera' : (!photoTaken ? 'Take Photo' : 'Retake Photo')}
                                    </Button>


                                </Box>
                            </Grid>
                        )}
                        <Grid item>
                            <FormControl component="fieldset">

                                <StyledFormLabel>How many years of experience do you have?</StyledFormLabel>
                                <RadioGroup
                                    aria-label="experience"
                                    name="experience"
                                    id="experience"
                                    value={experience}
                                    onChange={(e) => setExperience(e.target.value)}
                                >
                                    {experienceOptions.map((option, index) => (
                                        <FormControlLabel key={index} value={option} control={<Radio />} label={option} />
                                    ))}
                                </RadioGroup>
                                {submitted && isInvalid(experience) && <p style={{ color: 'red' }}>Experience is required</p>}
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <FormControl component="fieldset">
                                <StyledFormLabel>In a normal month, how many times per month do you go home?</StyledFormLabel>
                                <RadioGroup
                                    aria-label="goHome"
                                    name="goHome"
                                    value={goHome}
                                    id="goHome"
                                    onChange={(e) => setGoHome(e.target.value)}
                                >
                                    {goHomeOptions.map((option, index) => (
                                        <FormControlLabel key={index} value={option} control={<Radio />} label={option} />
                                    ))}
                                </RadioGroup>
                                {submitted && isInvalid(goHome) && <p style={{ color: 'red' }}>How often do you go home is required</p>}
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <FormControl component="fieldset">
                                <StyledFormLabel>Are you an owner operator?</StyledFormLabel>
                                <RadioGroup
                                    aria-label="ownerOperator"
                                    name="ownerOperator"
                                    id="ownerOperator"
                                    value={ownerOperator}
                                    onChange={(e) => setOwnerOperator(e.target.value)}
                                >
                                    {ownerOperatorOptions.map((option, index) => (
                                        <FormControlLabel key={index} value={option} control={<Radio />} label={option} />
                                    ))}
                                </RadioGroup>
                                {submitted && isInvalid(ownerOperator) && <p style={{ color: 'red' }}>Owner Operator is required</p>}
                            </FormControl>
                        </Grid>
                        {/* Have to use a box in order to get next button right aligned. */}
                        <Box display="flex" justifyContent="flex-start" paddingLeft={2}>
    <Button id="next" variant="contained" color="primary" type="submit">
        Next
    </Button>
</Box>
                    </Grid>
                </form>
            </CardContent>
        </StyledCard>
    );
};

export default Demographics;