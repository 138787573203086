import React, {  useEffect } from 'react';
import { Card, CardContent, Typography, Button, Grid } from '@mui/material';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
const config = require('./config');

const StyledCard = styled(Card)`
width: 80%;
margin: 2% auto;
padding: 20px;
max-height: 90vh;
overflow: auto;
`;


//comes before the consent screen so we can generate a treatment
const Initial = () => {
  const navigate = useNavigate();
  

  const handleNext = async () => {
    try {
      // Fetch treatment data
      const treatmentResponse = await fetch(`${config.baseURL}/get-treatment`);
      const treatmentData = await treatmentResponse.json();
  
      // Navigate to the next route with the fetched data
      navigate('/consent', { state: { treatment: treatmentData } });
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    
  }, []);
  
  return (
    <StyledCard>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
           
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" align="center">
            Click begin to start the survey. 
            </Typography>
          </Grid>
        
          <Grid item xs={12}  align="center"> 
            <Button variant="contained" color="primary" onClick={handleNext}>
              Begin
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </StyledCard>
  );
};

export default Initial;