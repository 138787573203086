import React, { useState, useEffect } from 'react';
import { Box, Card, Typography, CircularProgress, Rating, Button, FormControl, FormControlLabel, FormLabel, Grid, RadioGroup, Radio, TextField, Checkbox } from '@mui/material';
import styled from '@emotion/styled';
import { useLocation, useNavigate } from 'react-router-dom';
const config = require('./config');

const StyledCard = styled(Card)`
width: 80%;
margin: 2% auto;
padding: 20px;
max-height: 90vh;
overflow: auto;
`;
const StyledFormLabel = styled(FormLabel)`
  font-size: 1rem; /* adjust as needed */
  color: black; /* adjust as needed */
  font-weight: bold;
  &:focus {
    color: black;
  }
  &.Mui-focused {
    color: black;
  }
`;
//determines if each individual question is invalid
const isInvalid = (value) => value === '' || value === null || value === undefined;

const Questions2 = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { questionsId } = location.state;
  const [isLoading, setIsLoading] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const [paragraph, setParagraph] = useState('');
  const [trueFalse, setTrueFalse] = useState('');
  const [infoShared, setInfoShared] = useState({});

  const handleinfoSharedChange = (event) => {
    setInfoShared({ ...infoShared, [event.target.name]: event.target.checked });
  };

  useEffect(() => {
    if (questionsId) {
      setIsLoading(false);
    }
  }, [questionsId]);

  if (isLoading) {
    return <CircularProgress />;
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);
    //determines if each of the responses are valid or not
    const allValid = !isInvalid(paragraph) && !isInvalid(trueFalse) && !isInvalid(infoShared);
    if (!allValid) {
      console.log(infoShared);
      alert('Please answer all the questions before submitting.');
      // each question will display red text if it is not submitted. 
    } else {
    //convert the checkboxes to a string
    const infoSharedString = Object.entries(infoShared)
      .filter(([key, value]) => value)
      .map(([key, value]) => key)
      .join(',');
  
    fetch(`${config.baseURL}/second-questions/${questionsId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        paragraph,
        trueFalse,
        infoShared: infoSharedString,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the response as needed
        console.log(data);
        navigate('/thank-you', { state: { questionsId } });
      })
      .catch((error) => {
        // Handle the error as needed
        console.log(error);
      });
    }
  };

  return (
    <StyledCard>
      <form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={2} direction="column">

         

          <Grid item>
            <StyledFormLabel component="legend">27. Would you like your employer to share your name/photo so the consumers can see who delivered they goods? Why or why not? </StyledFormLabel>
            <TextField
              label="Answer why or not"
              multiline
              id="textBox"
              rows={8} // Increase this number to make the text box larger
              variant="outlined"
              value={paragraph}
              onChange={(e) => setParagraph(e.target.value)}
              fullWidth
            />
            {submitted && isInvalid(paragraph) && <p style={{ color: 'red' }}>This question is required. </p>}
          </Grid>
          <Grid item>
            <FormControl component="fieldset">
              <StyledFormLabel component="legend">28. If the final consumers would like to appreciate your work (know who delivered their product), do you want to share your name/photo with them?
              </StyledFormLabel>
              <RadioGroup
                aria-label="trueFalse"
                name="trueFalse"
                value={trueFalse}
                onChange={(e) => setTrueFalse(e.target.value)}
              >
                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="no" control={<Radio />} label="No" />
              </RadioGroup>
              {submitted && isInvalid(trueFalse) && <p style={{ color: 'red' }}>This question is required. </p>}
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl component="fieldset">
              <StyledFormLabel component="legend">29. If you have the choice, what information (if any) you would like to share with the final consumers?</StyledFormLabel>
              <FormControlLabel
                control={<Checkbox checked={infoShared.option1} onChange={handleinfoSharedChange} name="Picture" />}
                label="Picture"
              />
              <FormControlLabel
                control={<Checkbox checked={infoShared.option2} onChange={handleinfoSharedChange} name="First name" />}
                label="First name"
              />
              <FormControlLabel
                control={<Checkbox checked={infoShared.option3} onChange={handleinfoSharedChange} name="Accident free miles" />}
                label="Accident free miles"
              />
              <FormControlLabel
                control={<Checkbox checked={infoShared.option4} onChange={handleinfoSharedChange} name="Tenure with the firm" />}
                label="Tenure with the firm"
              />
              <FormControlLabel
                control={<Checkbox checked={infoShared.option5} onChange={handleinfoSharedChange} name="Employee awards" />}
                label="Employee awards"
              />
              <FormControlLabel
                control={<Checkbox checked={infoShared.option6} onChange={handleinfoSharedChange} name="Salary" />}
                label="Salary"
              />
              <FormControlLabel
                control={<Checkbox checked={infoShared.option7} onChange={handleinfoSharedChange} name="Average hours" />}
                label="Average hours"
              />
              <FormControlLabel
                control={<Checkbox checked={infoShared.option8} onChange={handleinfoSharedChange} name="Benefits" />}
                label="Benefits"
              />
            </FormControl>
        
            {submitted && isInvalid(infoShared) && <p style={{ color: 'red' }}>This question is required. </p>}
          </Grid>

          {/* Have to use a box in order to get next button right aligned. */}
          <Box display="flex" justifyContent="flex-end">
            <Button variant="contained" color="primary" type="submit">
              Submit
            </Button>
          </Box>
        </Grid>
      </form>
    </StyledCard>
  );
};

export default Questions2;